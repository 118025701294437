// axios
import * as axios from 'axios'
import store from './state/store'
import { CoreAPIConfig } from './state/api/config'

const coreUrl = `${CoreAPIConfig.COREAPI}`
const publicAPIInstance = new axios.create({ baseURL: coreUrl })
const parser = new UAParser()
const deviceResult = parser.getResult()

publicAPIInstance.interceptors.request.use(
  async (config) => {
    store.dispatch('setIsProgressing', true)
    config.headers.Authorization = `Bearer ${store.getters.loggedInUserToken}`
    config.headers['x-app'] = 'eventspace'    
    config.headers['x-device'] = JSON.stringify(deviceResult)
    return config
  },
  function (err) {
    store.dispatch('setIsProgressing', false)
    console.log(err.response.status)
    return Promise.reject(err)
  }
)

publicAPIInstance.interceptors.response.use(
  function (response) {
    store.dispatch('setIsProgressing', false)
    return response
  },
  function (err) {
    store.dispatch('setIsProgressing', false)
    console.log(err.response.status)
    return Promise.reject(err)
  }
)

export const axiosInstance = {
  publicAPIInstance,
}
